import React, { useEffect, useState } from "react"

import toast from "react-hot-toast"

import API from "../../../api/blogs"

import { Link } from "gatsby"

import { objectDeepClone } from "../../../service/helper"

import BlogCard from "../../addon/blogCard"
import LoadMoreButton from "../../addon/loadMoreButton"
import Loader from "../../addon/Loader"

const BlogsListingSection  = (props) => {

    const {
        additionalParams,
        openVideoModal,
        mostViewdArticles,
        redirectionLink,
        setMetaImage
    } = props


    let [loading, setLoading] = useState(false)
    let [showNoDataText, setShowNoDataText] = useState(false)
    let [totalCount, setTotalCount] = useState(0)
    let [data, setData] = useState([])
    let [queryParams, setQueryParams] = useState({})

    useEffect(() => {
        setQueryParams({
            ...additionalParams
        })
        setData([])
    }, [additionalParams])

    useEffect(() => {
        if (mostViewdArticles === true) {
            getArticles()
        } else {
            getData()
        }
    }, [queryParams])

    const getArticles = () => {
        setLoading(true)
        if (Object.keys(queryParams).length > 0) {
            API.getArticles(additionalParams)
            .then(response => {
                if (response && response.length > 0) {
                    let arr = objectDeepClone(data)
                    setData(arr.concat(response))
                    setShowNoDataText(false)
                } else {
                    setShowNoDataText(true)
                }
                setLoading(false)
            })
            .catch(err => {
                setLoading(false)
                console.log(err)
                toast.error("Something went wrong")
            })
        }
    }

    const getData = () => {
        setLoading(true)
        if (Object.keys(queryParams).length > 0) {
            API.getBlogsData(additionalParams)
            .then(response => {
                if (response && response.data.length > 0) {
                    let arr = objectDeepClone(data)
                    setData(arr.concat(response.data))
                    setTotalCount(response.count)
                    setShowNoDataText(false)
                    if(typeof window.initRatingFunction !== 'undefined') window.initRatingFunction()
                } else {
                    setData([])
                    setTotalCount(0)
                    setShowNoDataText(true)
                }
                setLoading(false)
            })
            .catch(err => {
                setLoading(false)
                console.log(err)
                toast.error("Something went wrong")
            })
        }
    }

    return (
        <> 
            <div className={`row`}>
                {
                    data && data.length > 0 &&
                    data.map((el, index) => {
                        return (
                            <div key={index} className={`col-lg-4 col-md-6 `}>
                                <BlogCard
                                    data={el}
                                    openVideoModal={openVideoModal}
                                    setMetaImage={setMetaImage}
                                />
                            </div>
                        )
                    })
                }
            </div>
            {
                // (true) &&
                (data.length < totalCount && !loading) &&
                <div className="btn_wrap text-center">
                    <Link to={redirectionLink} className="btn btn-primary">View All</Link>
                </div>
            }
            {
                loading === true &&
                <Loader loadingMessage="Loading..." />
            }
            {
                (showNoDataText === true && !loading) &&
                <div className="btn_wrap text-center">
                    <p>Currently there is no data</p>
                </div>
            }
        </>
    )
}

export default BlogsListingSection
