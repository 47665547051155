import React, { useState, useEffect } from "react"
import { graphql, Link } from "gatsby"

import Layout from "../components/layout/layout"
import Meta from "../components/addon/meta"
import ImageComponent from "../components/pageSections/imageComponent"
import CardBadge from "../components/addon/cardBadge"
import ShareButton from "../components/addon/shareButton"
import NewsLetter from "../components/addon/newsLetter"
import BlogsListingSection from "../components/pageSections/blogsListing"
import Loader from "../components/addon/Loader"
import VideoModal from "../components/addon/videoModal"

import useDeviceDetect from "../service/useDeviceDetect"
import useScript from "../service/useScript"
import { slugify } from "../service/helper"
import API from "../api/blogs"

const BlogListing = props => {
const { data } = props
useScript("/assets/js/bootstrap4-rating-input.js")
useScript("/assets/js/star_rating.js")
useScript("/assets/js/blog-slider.js")
useScript("/assets/js/custom_select.js")
const defaultImage = "https://via.placeholder.com/225x276"
const [categoryList, setCategoryList] = useState([])
const [videoURL, setVideoURL] = useState("")
const [showModal, setShowModal] = useState(false)
let bannerInfo = {}
let metaTag = []
let blogSlides = []
let mostViewdArticles = []
let totalMostViewedArticles = 0
let totalTopRatedArticles = 0
let totalVideoCount = 0
let topRatedArticles = []
let allVideos = []
const { isMobile } = useDeviceDetect()
const defaultBannerImageSrc =
isMobile === true
? "https://via.placeholder.com/360x209"
: "https://via.placeholder.com/1903x589"
const defaultSliderImageSrc =
isMobile === true
? "https://via.placeholder.com/360x111"
: "https://via.placeholder.com/1903x589"
let bannerImageSrc = ""
let bannerAltText = ""
if (data) {
if (data?.allEntitySubqueueBlogBanner?.nodes.length > 0) {
    if (
    data.allEntitySubqueueBlogBanner.nodes[0]?.articles?.items.length > 0
    ) {
    bannerInfo = data.allEntitySubqueueBlogBanner.nodes[0]?.articles?.items[0]
    bannerImageSrc =
    isMobile === true
    ? bannerInfo?.relationships?.image_mobile?.uri?.url
    : bannerInfo?.relationships?.image?.uri?.url
    bannerAltText = bannerInfo?.field_image.alt || "Hinduja Hospital"
}
}
if (
data?.allNodeTemplatePages?.edges &&
data.allNodeTemplatePages.edges.length > 0
) {
metaTag = data.allNodeTemplatePages.edges[0]?.node?.metatag
? data.allNodeTemplatePages.edges[0].node.metatag
: []
}
if (
data?.allEntitySubqueueBlogSlideshow?.nodes &&
data.allEntitySubqueueBlogSlideshow.nodes.length > 0
) {
blogSlides = data.allEntitySubqueueBlogSlideshow.nodes[0]?.articles?.items
? data.allEntitySubqueueBlogSlideshow.nodes[0].articles.items
: []
}
if (
data?.allEntitySubqueueMostViewedArticles?.nodes &&
data.allEntitySubqueueMostViewedArticles.nodes.length > 0
) {
mostViewdArticles = data.allEntitySubqueueMostViewedArticles.nodes[0]
?.articles?.items
? data.allEntitySubqueueMostViewedArticles.nodes[0].articles.items
: []
}
totalMostViewedArticles = data?.allEntitySubqueueMostViewedArticles
?.totalCount
? data.allEntitySubqueueMostViewedArticles.totalCount
: 0
if (
data?.allEntitySubqueueTopRatedArticles?.nodes &&
data.allEntitySubqueueTopRatedArticles.nodes.length > 0
) {
topRatedArticles = data.allEntitySubqueueTopRatedArticles.nodes[0]
?.articles?.items
? data.allEntitySubqueueTopRatedArticles.nodes[0].articles.items
: []
}
totalTopRatedArticles = data?.allEntitySubqueueTopRatedArticles?.totalCount
? data.allEntitySubqueueTopRatedArticles.totalCount
: 0
if (data?.allNodeArticle?.nodes && data.allNodeArticle.nodes.length > 0) {
allVideos = data.allNodeArticle.nodes
}
totalVideoCount = data?.allNodeArticle?.totalCount
? data.allNodeArticle.totalCount
: 0
}

const [params, setParams] = useState({
limit: 3,
offset: 0,
})
let [blogParams, setBlogParams] = useState({
...params,
type: "blog",
})
let [videoParams, setVideoParams] = useState({
...params,
type: "video",
})
const [mostViewedParams, setMostViewedParams] = useState({
name: "most_viewed_articles",
})
const [topRatedParams, setTopRatedParams] = useState({
name: "top_rated_articles",
})
let [loading, setLoading] = useState(false)
let [showNoDataText, setShowNoDataText] = useState(false)
let [topRatedArticlesData, setTopRatedArticles] = useState([])
//default routes
let [allArticlesDefaultRoute, setAllArticlesDefaultRoute] = useState("/blogs")
let [videosDefaultRoute, setVideosDefaultRoute] = useState("/videos")
let [allArticlesRoute, setAllArticlesRoute] = useState("/blogs")
let [videosRoute, setVideosRoute] = useState("/videos")
let [topRatedArticlesRoute, setTopRatedArticlesRoute] = useState(
"/top-rated-articles"
)
let [mostViewedArticlesRoute, setMostViewedArticlesRoute] = useState(
"/most-viewed-articles"
)
const [metaImage, setMetaImage] = useState("")
useEffect(() => {
getTags()
getTopRatedArticles()
}, [])
const getTopRatedArticles = () => {
API.getArticles(topRatedParams)
.then(response => {
if (response && response.length > 0) {
setTopRatedArticles(response)
setShowNoDataText(false)
} else {
setShowNoDataText(true)
}
setLoading(false)
})
.catch(err => {
setLoading(false)
console.log(err)
// addToast("Something went wrong", { appearance: "error" })
})
}
const getTags = () => {
API.getTags()
.then(response => {
if (response?.data && response.data.length) {
const arr = response.data.map(el => {
return {
name: el?.attributes?.name,
id: el?.attributes?.name,
}
})
setCategoryList(arr)
if (typeof window.initSelect !== "undefined") window.initSelect()
}
})
.catch(err => {
console.log(err)
})
}
const onCategorySelect = event => {
if (event.target.value) {
const category = event.target.value
if (category === "default") {
setVideosRoute(videosDefaultRoute)
setAllArticlesRoute(allArticlesDefaultRoute)
} else {
setVideosRoute(videosDefaultRoute + "/" + slugify(category))
setAllArticlesRoute(allArticlesDefaultRoute + "/" + slugify(category))
}
if (category === "default") {
setBlogParams({
...params,
type: "blog",
})
setVideoParams({
...params,
type: "video",
})
} else {
setBlogParams({
...blogParams,
category: category,
})
setVideoParams({
...videoParams,
category: category,
})
}
}
}
const openVideoModal = url => {
setVideoURL(url)
setShowModal(true)
}
const closeModal = () => {
setShowModal(false)
}
return (
<Layout>
    <Meta
    files={{
    js: [],
    css: ["/assets/css/blog.css"],
    }}
    tags={metaTag}
    imgSrc = {metaImage}
    />
    <main className="innerpage">
        <section className="hero_banner_blog">
            <picture className="banner_img">
                {bannerImageSrc ? (
                <img src={bannerImageSrc} alt={bannerAltText} />
                ) : (
                <>
                <source
                    media="(min-width:1024px)"
                    srcSet={defaultBannerImageSrc}
                    />
                <source
                    media="(min-width:300px)"
                    srcSet={defaultBannerImageSrc}
                    />
                <img
                    className="lazyload"
                    src={defaultBannerImageSrc}
                    alt={bannerAltText}
                    />
                </>
                )}
            </picture>
            <div className="banner_content">
                <div className="container">
                    <div className="banner_txt">
                        <div className="badge_wrap">
                            {bannerInfo?.relationships?.tags &&
                            bannerInfo.relationships.tags.length > 0 && (
                            <CardBadge
                                data={bannerInfo.relationships.tags[0]?.name}
                                />
                            )}
                            <span className="badge orange"> Trending</span>
                        </div>
                        <h2>
                            {bannerInfo.title || ""}
                            <br />
                            <span>{bannerInfo.created || ""}</span>
                        </h2>
                        <p>{bannerInfo?.summary?.summary || ""}</p>
                        {bannerInfo?.path?.alias && (
                        <Link
                        to={bannerInfo.path.alias}
                        className="btn-link"
                        aria-label={bannerInfo.title || ""}
                        >
                        Read More
                        </Link>
                        )}
                    </div>
                </div>
            </div>
        </section>
        <section className="section-bg section-py">
            <div className="container">
                <div className="row filter_row">
                    <div className="col-md-6">
                        <h2 className="section-heading">All Articles</h2>
                    </div>
                    <div className="col-md-6">
                        <div className="form-group select-dd">
                            <select
                                onChange={onCategorySelect}
                                name=""
                                id=""
                                className="form-control custom-select"
                                placeholder="Filter by Category"
                                >
                                <option value="default">All</option>
                                {categoryList &&
                                categoryList.length > 0 &&
                                categoryList.map((el, index) => {
                                return (
                                <option key={index} value={el.id}>
                                    {el.name}
                                </option>
                                )
                                })}
                            </select>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container">
                <BlogsListingSection
                    additionalParams={blogParams}
                    redirectionLink={allArticlesRoute}
                    setMetaImage={setMetaImage}
                    />
            </div>
        </section>
        <section className="section-py">
            <div className="container">
                <div className="row filter_row">
                    <div className="col-md-6">
                        <h2 className="section-heading">Videos</h2>
                    </div>
                </div>
            </div>
            <div className="container">
                <BlogsListingSection
                    additionalParams={videoParams}
                    openVideoModal={openVideoModal}
                    redirectionLink={videosRoute}
                    setMetaImage={setMetaImage}
                    />
            </div>
        </section>
        {blogSlides && blogSlides.length > 0 && (
        <section className="inner_banner_slider">
            <div className="banner_slider">
                {blogSlides.map((el, index) => {
                const imageSrc =
                isMobile === true
                ? el?.relationships?.image_mobile?.uri?.url
                : el?.relationships?.image?.uri?.url
                const altText = el?.field_image.alt || "Hinduja Hospital"
                return (
                <div className="slide" key={index}>
                    {imageSrc ? (
                    <img src={imageSrc} alt={altText} />
                    ) : (
                    <img src={defaultSliderImageSrc} alt={altText} />
                    )}
                    <div className="banner_content">
                        <div className="container">
                            <div className="banner_txt">
                                <div className="badge_wrap">
                                    {el?.relationships?.tags &&
                                    el.relationships.tags.length > 0 && (
                                    <CardBadge
                                        data={el.relationships.tags[0]?.name}
                                        />
                                    )}
                                </div>
                                <h2>
                                    {el.title || ""}
                                    <br />
                                    <span>{el.created || ""}</span>
                                </h2>
                                <p>{el?.summary?.summary || ""}</p>
                                {el?.path?.alias && (
                                <Link to={el.path.alias} className="btn-link">
                                Read More
                                </Link>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
                )
                })}
            </div>
        </section>
        )}
        <section className="section-bg section-py">
            <div className="container">
                <h2 className="section-heading">Top Rated Articles</h2>
                {topRatedArticlesData && topRatedArticlesData.length > 0 && (
                <>
                <div className="card_slider">
                    {topRatedArticlesData.map((el, index) => {
                    return (
                    <div className="slide" key={index}>
                        <div className="card blog_card">
                            <div className="img_video_wrapper">
                                {/* 
                                <ImageComponent
                                    content={el}
                                    />
                                */}
                                <img
                                className="lazyload"
                                src={defaultImage} // el?.headerImage ||
                                alt={el?.title || ""}
                                />
                            </div>
                            <div className="card-body">
                                <div className="badge_wrap">
                                    {el.category && 
                                    <CardBadge data={el.category} />
                                    }
                                    <ShareButton url={el.path} image={el?.headerImage || defaultImage} setMetaImage={setMetaImage}/>
                                </div>
                                <h3>{el.title || ""}</h3>
                                <div className="row mb-2">
                                    <div className="col-md-12 col-7">
                                        <div className="by_date">
                                            <span>{el.author || ""}</span> <br />
                                            <span>{el.date || ""}</span>
                                        </div>
                                    </div>
                                    <div className="col-md-12 col-5">
                                        <div className="star_wrap">
                                            {/* 
                                            <div className="starrating">
                                                <input type="number" name="inputName" id="rating1" className="rating" />      
                                            </div>
                                            <div className="comments_no">
                                                <a href="javascript:void(0)"> 50 Comments </a>
                                            </div>
                                            */}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="card-link">
                                {el.path && (
                                <Link
                                to={el.path}
                                className="btn-link"
                                aria-label={el.title || ""}
                                >
                                Read More
                                </Link>
                                )}
                            </div>
                        </div>
                    </div>
                    )
                    })}
                </div>
                </>
                )}
                {loading === true && 
                <Loader loadingMessage="Loading..." />
                }
                {showNoDataText === true && !loading && (
                <div className="load_more_wrap text-center">
                    <p>Currently there is no data</p>
                </div>
                )}
            </div>
        </section>
        <section className="section-py">
            <div className="container">
                <div className="row filter_row">
                    <div className="col-md-12">
                        <h2 className="section-heading">Most Viewed Articles</h2>
                    </div>
                </div>
            </div>
            <div className="container">
                <BlogsListingSection
                    additionalParams={mostViewedParams}
                    mostViewdArticles={true}
                    key="mostViewedArticles"
                    openVideoModal={openVideoModal}
                    redirectionLink={mostViewedArticlesRoute}
                    setMetaImage={setMetaImage}
                    />
            </div>
        </section>
        <NewsLetter />
    </main>
    <VideoModal
        videoURL={videoURL}
        showModal={showModal}
        closeModal={closeModal}
        />
</Layout>
)
}
export const query = graphql`
query blogBanner {
    allNodeTemplatePages(filter: {path: {alias: {regex: "/blog$/"}}}) {
      edges {
        node {
          id
          title
          path {
            alias
          }
          metatag {
            attributes {
              content
              href
              name
              property
              rel
            }
          }
        }
      }
    }
    
    
    allNodeArticle(
      filter: {field_article_type: {eq: "video"}}
      sort: {fields: created, order: DESC}
      limit: 3
      skip: 0
    ) {
      totalCount
      nodes {
        id
        title
        path {
          alias
        }
        created(formatString: "MMMM D,YYYY | hh:mm A")
        author: field_author
        title
        field_image {
          alt
        }
        summary: body {
          summary
        }
        relationships {
          image: field_image {
            id
            uri {
              value
              url
            }
          }
            
        }
      }
    }
    
  }
`
export default BlogListing


// query blogBanner {
//     allNodeTemplatePages(filter: {path: {alias: {regex: "/blog$/"}}}) {
//       edges {
//         node {
//           id
//           title
//           path {
//             alias
//           }
//           metatag {
//             attributes {
//               content
//               href
//               name
//               property
//               rel
//             }
//           }
//         }
//       }
//     }
//     allEntitySubqueueBlogBanner(limit: 1) {
//       nodes {
//         articles: relationships {
//           items {
//             title
//             path {
//               alias
//             }
//             created(formatString: "MMMM D,YYYY | hh:mm A")
//             author: field_author
//             title
//             field_image {
//               alt
//             }
//             summary: body {
//               summary
//             }
//             relationships {
//               image: field_image {
//                 id
//                 uri {
//                   value
//                   url
//                 }
//               }
//               image_mobile: field_image {
//                 id
//                 uri {
//                   value
//                   url
//                 }
//               }
//               tags: field_tags {
//                 id
//                 name
//               }
//             }
//           }
//         }
//       }
//     }
//     allEntitySubqueueBlogSlideshow {
//       nodes {
//         articles: relationships {
//           items {
//             title
//             path {
//               alias
//             }
//             created(formatString: "MMMM D,YYYY | hh:mm A")
//             author: field_author
//             title
//             field_image {
//               alt
//             }
//             summary: body {
//               summary
//             }
//             relationships {
//               image: field_image {
//                 id
//                 uri {
//                   value
//                   url
//                 }
//               }
//               image_mobile: field_image {
//                 id
//                 uri {
//                   value
//                   url
//                 }
//               }
//               tags: field_tags {
//                 id
//                 name
//               }
//             }
//           }
//         }
//       }
//     }
//     allNodeArticle(
//       filter: {field_article_type: {eq: "video"}}
//       sort: {fields: created, order: DESC}
//       limit: 3
//       skip: 0
//     ) {
//       totalCount
//       nodes {
//         id
//         title
//         path {
//           alias
//         }
//         created(formatString: "MMMM D,YYYY | hh:mm A")
//         author: field_author
//         title
//         field_image {
//           alt
//         }
//         summary: body {
//           summary
//         }
//         relationships {
//           image: field_image {
//             id
//             uri {
//               value
//               url
//             }
//           }
//           tags: field_tags {
//             id
//             name
//           }
//         }
//         field_video {
//           input
//           video_id
//         }
//       }
//     }
//     allEntitySubqueueMostViewedArticles {
//       nodes {
//         articles: relationships {
//           items {
//             title
//             path {
//               alias
//             }
//             created(formatString: "MMMM D,YYYY | hh:mm A")
//             author: field_author
//             title
//             field_image {
//               alt
//             }
//             summary: body {
//               summary
//             }
//             relationships {
//               image: field_image {
//                 id
//                 uri {
//                   value
//                   url
//                 }
//               }
//               tags: field_tags {
//                 id
//                 name
//               }
//             }
//           }
//         }
//       }
//       totalCount
//     }
//     allEntitySubqueueTopRatedArticles {
//       nodes {
//         articles: relationships {
//           items {
//             title
//             path {
//               alias
//             }
//             created(formatString: "MMMM D,YYYY | hh:mm A")
//             author: field_author
//             title
//             field_image {
//               alt
//             }
//             summary: body {
//               summary
//             }
//             relationships {
//               image: field_image {
//                 id
//                 uri {
//                   value
//                   url
//                 }
//               }
//               tags: field_tags {
//                 id
//                 name
//               }
//             }
//           }
//         }
//       }
//       totalCount
//     }
//   }